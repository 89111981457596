module.exports = {
  SITE_NAME: "Nakshewala",
  ADMIN_URL: "https://manage.nakshewala.com",
  //ADMIN_URL: "https://naksheadmin.webnoo.in.net",
  WEB_URL: "https://www.nakshewala.com",
  CANONICAL_URL: "https://www.nakshewala.com/",
  BASE_URL: "https://www.nakshewala.com",
  IDW_URL: "https:/idwinteriors.com",
  X_API_KEY: "ee80487e-a00e-4898-bc5a-ac5db6078d27",
  X_API_SECRET:
    "IEwJcMy8BcS4j7IqUxXQS1Tgyd43m1cHRGerBdTRqkJ9jNOmTjfUfWdOs8YVdDKgQo8VSLdtWkpIcbRIQLQhv0o8MwQCOTnS66ydzxQBbmO4dbTvH2HqfqjfxuCXeQ5G",
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
};
